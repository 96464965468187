
import BHorizontalDivider from 'buetify/lib/components/layout/divider/BHorizontalDivider';
import { defineComponent } from 'vue';
import ApiView from '../../../../components/apiView/ApiView.vue';
import ExampleView from '../../../../components/exampleView/ExampleView.vue';
import FontAwesomeExample from './examples/FontAwesomeExample.vue';
import FontAwesomeExampleCode from '!!raw-loader!./examples/FontAwesomeExample.vue';
import { apis } from './api';

export default defineComponent({
	name: 'icon-documentation',
	components: {
		ApiView,
		ExampleView,
		FontAwesomeExample,
		BHorizontalDivider
	},
	setup() {
		return {
			apis,
			FontAwesomeExampleCode
		};
	}
});
