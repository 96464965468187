
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons/faTachometerAlt';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { BButton } from 'buetify/lib/components';
import { useFontAwesomeIconComponent } from 'buetify/lib/components/icons/utils';
import { defineComponent } from 'vue';

const TachometerAltIcon = useFontAwesomeIconComponent('TachometerAlt', faTachometerAlt);
const UserIcon = useFontAwesomeIconComponent('User', faUser);
const HomeIcon = useFontAwesomeIconComponent('Home', faHome);
const CheckIcon = useFontAwesomeIconComponent('Check', faCheck);
const SyncAltIcon = useFontAwesomeIconComponent('SyncAlt', faSyncAlt);

export default defineComponent({
	name: 'font-awesome-icons-example',
	components: {
		BButton,
		UserIcon,
		HomeIcon,
		CheckIcon,
		SyncAltIcon,
		TachometerAltIcon
	}
});
